// @flow strict
import React from 'react';
import { useSiteMetadata } from '../hooks';

const NotFoundTemplate = () => {
  const { title, subtitle } = useSiteMetadata();

  return (
    <div title={`Not Found - ${title}`} description={subtitle}>
      <div />
      <div title="NOT FOUND">
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </div>
  );
};

export default NotFoundTemplate;
